import React from "react"
import Button from "./Button"
import TShirtImage from "./TShirtImage"
import "./SKUCard.css"

const formatPrice = (amount, currency) => {
  const price = (amount / 100).toFixed(2)

  const numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })
  return numberFormat.format(price)
}

const SkuCard = ({ sku }) => {
  return (
    <div className="SKUCard">
      <div>
        <TShirtImage />
        <h4>{sku.attributes.name}</h4>
        <p>Price: {formatPrice(sku.price, sku.currency)}</p>
        <Button handleClick={() => null} disabled>
          Coming Soon
        </Button>
      </div>
    </div>
  )
}

export default SkuCard
