import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

function TShirtImage() {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "tshirt-image.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => {
        console.log(data);
        
        return (
          <Img
            imgStyle={{ width: "100%" }}
            fluid={data.file.childImageSharp.fluid}
            alt="Drop the weights. Pick up the rings Tshirt"
          />
        )
      }}
    />
  )
}

export default TShirtImage
