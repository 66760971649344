import React from "react"
import classnames from "classnames"
import "./Button.css"

function Button(props) {
  const { children, handleClick, disabled } = props

  return (
    <button
      onClick={handleClick}
      type="button"
      className={classnames("Button", {
        "Button--disabled": disabled,
      })}
    >
      <p className="Button__text">{children}</p>
    </button>
  )
}

export default Button
