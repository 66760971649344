import React from "react"
import SkuCard from "./SkuCard"
import "./SKUs.css"

const SKUs = () => {
  const skus = [
    {
      id: 1,
      attributes: { name: "Drop the weight, Pick up the Rings T-Shirt" },
      price: 2000,
      currency: "gbp",
    },
  ]

  return (
    <div className="SKUs">
      {skus.map(sku => (
        <SkuCard key={sku.id} sku={sku} />
      ))}
    </div>
  )
}

export default SKUs
