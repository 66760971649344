import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SKUs from "../components/SKUs"

const IndexPage = () => (
  <Layout>
    <SEO title="Calisthenics Skills | Drop the Weights, Pick up the Rings" />
    <p>
      Note: We're currently working to get our shop set up with a payment
      provider, so purchases can't be made through our website just yet.
    </p>
    <p>
      If you'd like to purchase a T-Shirt, send us an{" "}
      <a href="mailto:karoulla.andrico@gmail.com">email</a> and we'll sort out a
      payment via PayPal.
    </p>
    <SKUs />
    <h3>About our T-Shirts</h3>
    <p>
      Printed by 3rd Rail
      <br />
      Material by AS Colour
      <br />
      Design by Kirsten Browning
      <br />
      Color: Asphalt Heather, Staple Tee.
      <br />
      Fit: Regular fit, crew neck.
      <br />
      Materials: 80% combed cotton, 20% polyester
      <br />
      <a
        href="https://ascolour.co.uk/responsible-sourcing"
        target="_blank"
        rel="noopener noreferrer"
      >
        Responsibly sourced
      </a>
    </p>
  </Layout>
)

export default IndexPage
